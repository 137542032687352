<template>
  <div class="navbar-container d-flex content align-items-center">
    <!--- IP LOGO-->
    <img
      class="cursor-pointer"
      src="https://connect-cdn.intellectualpoint.com/assets/images/logos/IntellectualPointIcon.png"
      width="20"
      @click="$router.push('/students')"
    />

    <!-- breadcrums -->
    <div class="align-items-center breadcrumb d-lg-none">
      <!--- MOBILE -->
      <div v-for="item in $route.meta.breadcrumb" :key="item.text" class="breadcrumb-item" v-if="item.text !== 'Management'">
        <router-link :to="item.to" v-if="item.to" :class="item.active ? 'breadcrumb-active' : ''">{{ item.text }}</router-link>
        <span :class="item.active ? 'breadcrumb-active' : ''" v-else>{{ item.text }}</span>
      </div>
    </div>

    <!-- breadcrums -->
    <div class="align-items-center breadcrumb d-none d-lg-flex">
      <!--- PC -->
      <div v-for="item in $route.meta.breadcrumb" :key="item.text" class="breadcrumb-item">
        <router-link :to="item.to" v-if="item.to" :class="item.active ? 'breadcrumb-active' : ''">{{ item.text }}</router-link>
        <span :class="item.active ? 'breadcrumb-active' : ''" v-else>{{ item.text }}</span>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end">
      <!-- Nav Menu Toggler -->
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link class="nav-link" @click="toggleVerticalMenuActive">
            <feather-icon icon="MenuIcon" size="21" />
          </b-link>
        </li>
      </ul>
    </div>
    <!-- <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <dark-toggler class="d-none d-lg-block" /> -->
    <!-- <all-bar /> -->
    <!-- <notification-dropdown /> -->
    <!-- <div />

      <user-dropdown-navbar class="d-block d-xl-none" /> -->
    <!-- </b-navbar-nav>  -->

    <!--- Ask Athena -->
    <ask-athena />
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import AskAthena from './components/AskAthena.vue';

export default {
  components: {
    BLink,

    AskAthena,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
