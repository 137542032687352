<template>
  <button class="shimmer-button" @click.prevent="$root.$emit('openGlobalSearch')">
    <feather-icon icon="SearchIcon" size="16" />
    <span> Search</span>
  </button>
</template>

<script>
export default {
  name: 'ShimmerButton',
  methods: {
    openGlobalSearch() {
      this.$root.emit('openGlobalSearch');
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.shimmer-button {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  margin-right: 0.25rem;
  text-wrap: nowrap;
  border-radius: 0.375rem; // Rounded corners for a modern look
  border: 1px solid #2a2c31; // Subtle border to elevate the design
  background-color: #1d1f24; // Deep, dark base color for contrast
  background-image: linear-gradient(
    155deg,
    // Increased angle for a more dynamic effect
    #1d1f24 0%,
    #0059ff 30%,
    // Making the blue shimmer more pronounced
    #1d1f24 60%
  );
  background-size: 400% 400%; // Enlarged background size for a smooth transition
  padding: 0.35rem 1.5rem; // Slightly increased padding for aesthetic balance
  font-size: 1rem;
  color: #f8fafc; // Maintains readability
  cursor: pointer;
  animation: shimmer 5s linear infinite; // Keeping the extended duration for smoothness
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1); // Smooth cubic transition for hover

  &:hover {
    background-color: #2c2e3d; // Slightly lighter on hover for interaction feedback
    border-color: #343637; // Enhanced border visibility on hover
    color: #ffffff; // Brighter text for contrast
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px #0059ff; // Consistent blue focus ring
  }
}
</style>
