<template>
  <div class="root-wrapper">
    <div class="root-card">
      <b-navbar
        :toggleable="false"
        :variant="navbarBackgroundColor"
        class="header-navbar navbar align-items-center"
        :class="[navbarTypeClass]"
      >
        <slot
          name="navbar"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :navbarBackgroundColor="navbarBackgroundColor"
          :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar align-items-center']"
        >
          <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </slot>
      </b-navbar>
      <!-- <div class="root-content"> -->
      <div
        class="app-content content"
        :class="[
          {
            'show-overlay': $store.state.app.shallShowOverlay,
          },
          $route.meta.contentClass,
        ]"
      >
        <transition :name="routerTransition" mode="out-in"> <slot /> </transition>
      </div>
      <div class="content-overlay" />
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
import { BNavbar } from 'bootstrap-vue';
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue';
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout';

export default {
  components: {
    BNavbar,
    AppNavbarVerticalLayout,
  },
  watch: {
    toggleVerticalMenuActive() {
      this.$emit('update:toggleVerticalMenuActive', true);
    },
  },
  props: {
    toggleVerticalMenuActive: {
      required: true,
    },
  },
  setup(props) {
    const { routerTransition, contentWidth, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden } = useAppConfig();

    const {
      isVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType);

    return {
      routerTransition,
      contentWidth,
      navbarBackgroundColor,
      footerType,
      navbarType,
      isNavMenuHidden,

      isVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    };
  },
};
</script>

<style></style>
