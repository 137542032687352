/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

export default [
  // ...dashboard,

  {
    title: 'Students',
    lcIcon: 'Users',
    route: 'apps-students-list',
    permission: 'student.list',
  },

  {
    header: 'Roster',
    permission: 'ui.nav.roster',
  },

  {
    title: 'Pathways',
    lcIcon: 'Library',
    route: 'apps-roster-pathway-list',
    permission: 'roster.pathway.list',
    children: [
      {
        title: 'List Pathways',
        faIcon: 'fa-regular fa-list',
        route: 'apps-roster-pathway-list',
        permission: 'roster.pathway.list',
      },
      {
        title: 'All Cohorts',
        faIcon: 'fa-regular fa-border-all',
        route: 'apps-roster-pathway-cohort-list',
        permission: 'roster.cohort.list',
      },
      {
        title: 'Live Cohorts',
        faIcon: 'fa-regular fa-circle-dot',
        route: 'apps-roster-pathway-cohort-live-list',
        permission: 'roster.cohort.list',
      },
      {
        title: 'Upcoming Cohorts',
        faIcon: 'fa-regular fa-clock',
        route: 'apps-roster-pathway-cohort-upcoming-list',
        permission: 'roster.cohort.list',
      },
    ],
  },
  {
    title: 'Courses',
    lcIcon: 'Book',
    route: 'apps-roster-course-list',
    permission: 'roster.course.list',
    children: [
      {
        title: 'List Courses',
        faIcon: 'fa-regular fa-list',
        route: 'apps-roster-course-list',
        permission: 'roster.course.list',
      },
      {
        title: 'All Sections',
        faIcon: 'fa-regular fa-border-all',
        route: 'apps-roster-course-section-list',
        permission: 'roster.section.list',
      },
      {
        title: 'Live Sections',
        faIcon: 'fa-regular fa-circle-dot',
        route: 'apps-roster-course-section-live-list',
        permission: 'roster.section.list',
      },
      {
        title: 'Upcoming Sections',
        faIcon: 'fa-regular fa-clock',
        route: 'apps-roster-course-section-upcoming-list',
        permission: 'roster.section.list',
      },
    ],
  },
  {
    title: 'Profiles',
    faIcon: 'fa-regular fa-address-card',
    route: 'apps-roster-profile-list',
    permission: 'roster.profile.list',

    children: [
      {
        title: 'List Roster Profiles',
        faIcon: 'fa-regular fa-list',
        route: 'apps-roster-profile-list',
        permission: 'roster.profile.list',
      },
      {
        title: 'Pending Roster Profiles',
        faIcon: 'fa-regular fa-clock',
        route: 'apps-roster-profile-list-pending',
        permission: 'roster.profile.list',
      },
    ],
  },

  {
    header: 'Sites',
    permission: 'ui.nav.roster.sessions',
  },
  {
    title: 'Campuses',
    faIcon: 'fa-regular fa-school',
    route: 'apps-sites-campus-list',
    permission: 'site.campus.list',
  },
  {
    title: 'Buildings',
    faIcon: 'fa-regular fa-buildings',
    route: 'apps-sites-building-list',
    permission: 'site.building.list',
  },
  {
    title: 'Rooms',
    faIcon: 'fa-regular fa-chalkboard',
    route: 'apps-sites-room-list',
    permission: 'site.room.list',
  },
  {
    header: 'Placement',
    permission: 'integrations.monday.template.list',
  },
  {
    title: 'Monday',
    faIcon: 'fa-kit fa-monday',
    route: 'apps-placement-monday-template-list',
    permission: 'integrations.monday.template.list',
  },
  {
    header: 'Accounting',
    permission: 'accounting.receivable.account.list',
  },
  {
    title: 'Receivable',
    faIcon: 'fa-solid fa-dollar-sign',
    permission: 'accounting.receivable.account.list',
    children: [
      {
        title: 'Accounts',
        route: 'apps-accounting-receivable-account-list',
        permission: 'accounting.receivable.account.list',
      },
      {
        title: 'Cohorts',
        route: 'apps-accounting-receivable-cohort-list',
        permission: 'accounting.receivable.cohort.list',
      },
      {
        title: 'Templates',
        route: 'apps-accounting-receivable-template-list',
        permission: 'accounting.receivable.template.list',
      },
      {
        title: 'Reconcile',
        route: 'apps-accounting-receivable-reconcile',
        permission: 'accounting.receivable.reconcile',
      },
      {
        title: 'Report',
        route: 'apps-report-list',
        permission: 'report.list',
      },
    ],
  },
  {
    header: 'Management',
    permission: 'ui.nav.management',
  },
  {
    title: 'Staff',
    icon: 'UserIcon',
    route: 'apps-users-list',
    permission: 'user.create',
  },
  {
    title: 'Jobs',
    icon: 'DownloadCloudIcon',
    route: 'apps-job-list',
    permission: 'export.list',
  },
  {
    title: 'Audit',
    icon: 'EyeIcon',
    route: 'apps-audit-audit-list',
    permission: 'audit.audit.list',
  },
];
