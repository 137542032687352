<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25 w-100">
      Copyright © {{ new Date().getFullYear() }}
      <b-link class="ml-25" href="https://www.intellectualpoint.com" target="_blank">Intellectual Point</b-link>. All rights reserved.

      <span class="ml-auto float-right"> app ver {{ version }}, git {{ branch }}/{{ commit }} </span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION || 'unknown';
    },
    branch() {
      return process.env.VUE_APP_GIT_BRANCH || 'unknown';
    },
    commit() {
      return process.env.VUE_APP_GIT_COMMIT ? process.env.VUE_APP_GIT_COMMIT.substring(0, 7) : 'unknown';
    },
  },
};
</script>
