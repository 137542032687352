<template>
  <b-navbar-nav class="nav align-items-center ml-auto" name="Sidebar">
    <b-nav-item-dropdown toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
      <template #button-content>
        <b-avatar
          size="32"
          :src="userData.avatar"
          :text="userData.name.substr(0, 1)"
          variant="light-info"
          class="badge-minimal"
          style="margin-right: 12px"
        >
          <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
        </b-avatar>
        <div class="user-nav">
          <h5 class="user-name font-weight-bolder mb-0" style="margin-top: 1px">
            {{ userData.name || userData.username }}
          </h5>
        </div>
      </template>

      <b-dropdown-divider v-if="false" />

      <b-dropdown-item :to="{ name: 'pages-account-setting' }" link-class="d-flex align-items-center">
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        <span>Settings</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center" @click="changeThemeOpen = true">
        <feather-icon size="16" icon="SunIcon" class="mr-50" />
        <span>Change Theme</span>
      </b-dropdown-item>

      <b-modal
        ref="modalCreateCampus"
        :visible="changeThemeOpen"
        @hidden="changeThemeOpen = false"
        :title="`Change Theme`"
        centered
        body-class="pt-0"
        hide-footer
      >
        <div
          class="d-flex justify-content-center align-items-center rounded-lg p-2 mb-1 cursor-pointer"
          @click="skin = 'midnight'"
          style="background-color: #0f1011"
          :style="skin === 'midnight' ? 'border: 2px solid #0053ff ' : 'border: 1px solid #222328'"
        >
          <feather-icon size="32" icon="MoonIcon" class="mr-2" />
          <h2 class="tracking-tighter font-weight-bolder text-center mb-0">Lights Out</h2>
        </div>
        <div
          class="d-flex justify-content-center align-items-center rounded-lg p-2 mb-1 cursor-pointer"
          @click="skin = 'dark'"
          style="background-color: #191a22"
          :style="skin === 'dark' ? 'border: 2px solid #0053ff ' : 'border: 1px solid #292b38'"
        >
          <feather-icon size="32" icon="MoonIcon" class="mr-2" />
          <h2 class="tracking-tighter font-weight-bolder text-center mb-0">Dim</h2>
        </div>
        <div
          class="d-flex justify-content-center align-items-center rounded-lg p-2 cursor-pointer"
          @click="skin = 'blue'"
          style="background-color: #1a2029"
          :style="skin === 'blue' ? 'border: 2px solid #0053ff ' : 'border: 1px solid #2a3443'"
        >
          <feather-icon size="32" icon="SunIcon" class="mr-2" />
          <h2 class="tracking-tighter font-weight-bolder text-center mb-0">Original</h2>
        </div>
      </b-modal>

      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item></b-nav-item-dropdown
    >
  </b-navbar-nav>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BNavbarNav, BRow, BCol } from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';
import { avatarText } from '@core/utils/filter';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
  components: {
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')) || {},
      changeThemeOpen: false,
      avatarText,
    };
  },
  methods: {
    logout() {
      return useJwt.logout();
    },
  },
  setup() {
    const { skin } = useAppConfig();

    return { skin };
  },
};
</script>
